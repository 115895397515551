$snow: #fcf7f8;
$day: #007991;
$night: #092429;
$highlightRed: #911800;

.container {
  transition: background 2s linear;
}

.main {
  font-family: 'Raleway', sans-serif;
  min-height: 100%;
  color: $snow;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  background: $day;

  transition: background 2s linear, color 2s linear;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dark {
  background: $night;
  color: $snow;

  a {
    color: $snow;
    transition: background 2s linear, color 0.3s linear;
  }

  h2 {
    color: $snow;
    text-shadow: $highlightRed 2px 3px;
  }

  button {
    color: $night;
    transition: color 2s linear;
  }
}

h1 {
  font-size: 5em;
  margin: 0 0 0.3em;
  color: $snow;
  text-shadow: $highlightRed 4px 5px;
}

h2 {
  font-family: 'Fredoka One', cursive;
  font-weight: 300;
  margin-top: 0;
  font-size: 2em;
  color: $snow;
  text-shadow: $highlightRed 2px 3px;
}

p {
  font-size: 1.2em;
  margin: 0.3em 0;
  font-weight: 700;
  text-align: center;
}

a {
  text-decoration: none;
  color: $snow;
  background: none;
  transition: all 0.3s linear;

  &:hover {
    color: $highlightRed;
    transition: all 0.3s linear;
  }
}

.btn {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  margin-bottom: 1em;
  width: 75vw;
  max-width: 400px;
  font-size: 1.2em;
  font-weight: 300;
}

button {
  font-family: 'Fredoka One', cursive;
  padding: 0.7em;
  border: none;
  background: $snow;
  color: $day;
  min-width: 20vw;
  border-radius: 6px;
  font-size: 1.3em;
  margin-bottom: 0.5em;
  outline: none;
  transition: color 2s linear;

  &:hover {
    border: none;
    background: $snow;
    cursor: pointer;
    transition: all 0.8s, color 2s linear;
  }
}

.manage-links {
  margin-top: 1em;
  width: 70%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  font-size: 0.9em;
  background: none;
}

.light {
  font-weight: 100;
}

.separator {
  height: 0.15em;
  width: 20%;
  background: $highlightRed;
  margin-bottom: 1em;
}
